import { useSuspenseQuery } from "@tanstack/react-query";
import {
  globalQueryClient,
  QueryConfig,
  SuspenseQueryConfig,
} from "../../../lib/react-query";
import { listAccountsUseCase } from "../../../main/factories/use-cases";
import { useUserStore } from "../../../presentation";
import { accountsQueryKeyFactory } from "./accounts-query-key-factory";

export type UseListAccounts = {
  config?: QueryConfig<typeof listAccountsUseCase.execute>;
};

export type UseSuspenseListAccounts = {
  config?: SuspenseQueryConfig<typeof listAccountsUseCase.execute>;
};

const fiveMinutes = 5 * 60 * 1000;

export function listAccountsQueryConfig({ config }: UseListAccounts = {}) {
  return {
    queryKey: accountsQueryKeyFactory.list(),
    queryFn: listAccountsUseCase.execute,
    staleTime: fiveMinutes,
    ...config,
  };
}

export function listAccountsSuspenseQueryConfig({
  config,
}: UseSuspenseListAccounts = {}) {
  return {
    queryKey: accountsQueryKeyFactory.list(),
    queryFn: listAccountsUseCase.execute,
    staleTime: fiveMinutes,
    ...config,
  };
}

export function prefetchListAccounts() {
  return globalQueryClient.prefetchQuery(listAccountsQueryConfig());
}

export const useListAccounts = ({ config }: UseListAccounts = {}) => {
  const userStore = useUserStore();
  const query = useSuspenseQuery(listAccountsQueryConfig({ config }));
  if (query.data.isSuccess()) {
    userStore.updateUser(query.data.value);
  }
  return query;
};
