import { CustomStorageImpl } from "../../../infra/cache/custom-storage-impl";
import { LocalStorageAdapter } from "../../../infra/cache/local-storage-adapter";

type StorageState = {
  name: string;
  accountId: string;
};

let persistentStore: CustomStorageImpl<StorageState | null> | null = null;

export function makePersistentStoreFactory(): CustomStorageImpl<StorageState | null> {
  if (!persistentStore) {
    persistentStore = new CustomStorageImpl<StorageState | null>({
      initialState: null,
      storageKey: "current-account-store",
      storage: new LocalStorageAdapter(),
    });
  }
  return persistentStore;
}

export const storage = makePersistentStoreFactory();
