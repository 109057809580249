export type EventName =
  | "CANCEL_INSTALLMENT"
  | "REQUEST_TOKEN"
  | "CONTROL_INSTALLMENT"
  | "CLOSE_DRAWER"
  | "EDIT_RECURRENCE_CARD"
  | "CANCEL_SINGLE_INSTALLMENT"
  | "ANTICIPATE_INSTALLMENT"
  | "POSTPONE_INSTALLMENT"
  | "GENERATE_BOLETO"
  | "CREATE_DIGITAL_SALE_LINK"
  | "CREATE_DIGITAL_SALE"
  | "CREATE_DIGITAL_SALE_RECURRENCE";

export type EventValue =
  | "@hyperlocal-token-validation/cancel-installments"
  | "@hyperlocal-token-validation/token-request"
  | "@hyperlocal-token-validation/close-drawer"
  | "@hyperlocal-token-validation/pause-installments"
  | "@hyperlocal-token-validation/edit-recurrence-card"
  | "@hyperlocal-token-validation/cancel-single-installment"
  | "@hyperlocal-token-validation/anticipate-installment"
  | "@hyperlocal-token-validation/postpone-installment"
  | "@hyperlocal-token-validation/generate-boleto"
  | "@hyperlocal-token-validation/create-digital-sale-link"
  | "@hyperlocal-token-validation/create-digital-sale"
  | "@hyperlocal-token-validation/create-digital-sale-recurrence";

export const eventIdentifierMap: Record<EventName, EventValue> = {
  CANCEL_INSTALLMENT: "@hyperlocal-token-validation/cancel-installments",
  REQUEST_TOKEN: "@hyperlocal-token-validation/token-request",
  CONTROL_INSTALLMENT: "@hyperlocal-token-validation/pause-installments",
  CLOSE_DRAWER: "@hyperlocal-token-validation/close-drawer",
  EDIT_RECURRENCE_CARD: "@hyperlocal-token-validation/edit-recurrence-card",
  ANTICIPATE_INSTALLMENT: "@hyperlocal-token-validation/anticipate-installment",
  CANCEL_SINGLE_INSTALLMENT:
    "@hyperlocal-token-validation/cancel-single-installment",
  POSTPONE_INSTALLMENT: "@hyperlocal-token-validation/postpone-installment",
  GENERATE_BOLETO: "@hyperlocal-token-validation/generate-boleto",
  CREATE_DIGITAL_SALE_LINK:
    "@hyperlocal-token-validation/create-digital-sale-link",
  CREATE_DIGITAL_SALE: "@hyperlocal-token-validation/create-digital-sale",
  CREATE_DIGITAL_SALE_RECURRENCE:
    "@hyperlocal-token-validation/create-digital-sale-recurrence",
};

export const getEventKey = (eventValue: EventValue) => {
  const eventKeys = Object.keys(eventIdentifierMap) as EventName[];
  return eventKeys.find((key) => eventIdentifierMap[key] === eventValue);
};
export const getEventIdentifier = (eventName: EventName) => {
  return eventIdentifierMap[eventName];
};
