export interface IMobileDetect {
  isAndroid: () => boolean;
  isIos: () => boolean;
  isSSR: () => boolean;
  isMobile: () => boolean;
  isDesktop: () => boolean;
}

const getMobileDetect = (userAgent: string): IMobileDetect => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i));
  const isMobile = (): boolean =>
    Boolean(
      (window.innerWidth && window.innerWidth <= 720) ||
        isAndroid() ||
        isIos() ||
        isOpera() ||
        isWindows(),
    );
  const isDesktop = (): boolean =>
    Boolean(
      (window.innerWidth && window.innerWidth > 720) ||
        (!isMobile() && !isSSR()),
    );

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};
const useMobileDetect = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  const mobileDetect = getMobileDetect(userAgent);

  return mobileDetect;
};

export default useMobileDetect;
