import { useAccountStore, useUserStore } from "../../presentation";

import { getVertical, Vertical } from "../../utils";

const useCheckVertical = (vertical: Vertical) => {
  try {
    const user = useUserStore((state) => state.user);
    const currentAccountId = useAccountStore((state) => state.currentAccountId);
  
    const currentAccount = user?.accounts.find(
      ({ accountId }) => accountId === currentAccountId
    );
  
    const accountVerticals = currentAccount?.vertical?.toLowerCase();
  
    return getVertical() === vertical || accountVerticals === vertical;
  } catch(_) {
    return false;
  }
};

export default useCheckVertical;
