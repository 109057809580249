import { failure, success } from "../../domain";
import { UserStore } from "../../domain/store";
import {
  ListAccountstUseCaseResponse,
  ListAccountsUseCase,
} from "../../domain/use-cases";
import {
  HTTP_STATUS_CODES,
  HttpClient,
  ListAccountsResponseDto,
  UserMapper,
} from "../../infra";

export class ListAccountsUsecaseImpl implements ListAccountsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly userStore: UserStore,
  ) {}

  async execute(): Promise<ListAccountstUseCaseResponse> {
    const response = await this.httpClient.request<ListAccountsResponseDto>({
      method: "GET",
      url: this.url,
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.statusCode) {
      case HTTP_STATUS_CODES.ok:
        this.userStore.updateUser(response.body);
        return success(UserMapper.toHttpResponse(response.body));
      case HTTP_STATUS_CODES.badRequest:
        return failure("Usuário não encontrado.");
      case HTTP_STATUS_CODES.unauthorized:
        return failure("Usuário não autorizado.");
      default:
        return failure("Erro inesperado.");
    }
  }
}
