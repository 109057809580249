import create, { StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
import { CardsRoutes } from "./types";

interface ICardsRoutesStore {
  routes: CardsRoutes;
  setRoutes: (data: CardsRoutes) => void;
}

type IRoutesStorePersist = (
  config: StateCreator<ICardsRoutesStore>,
  options: PersistOptions<ICardsRoutesStore>,
) => StateCreator<ICardsRoutesStore>;

const cardsRoutesStore = create<ICardsRoutesStore>(
  (persist as unknown as IRoutesStorePersist)(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "CardsRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getCardsRoutesData() {
  const cardsRoutes = cardsRoutesStore.getState().routes;
  return cardsRoutes;
}

export function setCardsRoutesData(data: object) {
  const setCardsRoutes = cardsRoutesStore.getState().setRoutes;
  setCardsRoutes(data);
}
