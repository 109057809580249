import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ReceivedRoutes } from "./types";

interface ReceivedRoutesState {
  routes: ReceivedRoutes;
  setRoutes: (data: ReceivedRoutes) => void;
}

const receivedRoutesStore = create<ReceivedRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "ReceivedRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getReceivedRoutesData() {
  return receivedRoutesStore.getState().routes;
}

export function setReceivedRoutesData(data: ReceivedRoutes) {
  receivedRoutesStore.getState().setRoutes(data);
}
