import {
  IDENTITY_MOBILE_TOKEN,
  IDENTITY_SDK_VERSION,
  IDENTITY_TOKEN,
} from "../config";

export function injectIdentitySdkScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (window["@combateafraude/identity-sdk"]) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = `https://repo.combateafraude.com/identity/${IDENTITY_SDK_VERSION}/index.umd.js`;
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      console.log("Script do Identity SDK carregado com sucesso");
      resolve();
    };

    script.onerror = () => {
      console.error("Erro ao carregar o script do Identity SDK");
      reject(new Error("Falha ao carregar o script do Identity SDK"));
    };

    document.head.appendChild(script);
    resolve();
  });
}

export function initializeIdentitySdk() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__webpack_public_path__ = "/public/";
  const identityToken = IDENTITY_TOKEN;
  const optionsIdentity = {
    theme: {
      closeButton: "#000037",
      checkmark: "#000037",
      loader: "#000037",
      buttonSuccessColor: "#000037",
      inputSuccessColor: "#000037",
      buttonFinishColor: "#000037",
    },
    authIcon: (new Image().src = "/public/identity.png"),
    mobileToken: IDENTITY_MOBILE_TOKEN,
  };

  if (!window["@combateafraude/identity-sdk"]) {
    throw new Error("Identity SDK não foi carregado corretamente");
  }

  const identity = new window["@combateafraude/identity-sdk"].Sdk(
    identityToken,
    optionsIdentity,
  );

  return identity;
}
