import { format as formatDateFns } from "date-fns";
import { pt } from "date-fns/locale";

type Options = {
  formatTimezone?: boolean;
};

export const formatDate = (
  rawDate: string | Date,
  format: string,
  options?: Options,
) => {
  const date = new Date(rawDate);

  if (options?.formatTimezone) {
    const timeZoneDate = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000,
    );

    const formattedDate = formatDateFns(timeZoneDate, format, {
      locale: pt,
    });

    return formattedDate;
  }

  const formattedDate = formatDateFns(date, format, {
    locale: pt,
  });

  return formattedDate;
};
