import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CashOutRoutes } from "./types";

interface CashOutRoutesState {
  routes: CashOutRoutes;
  setRoutes: (data: CashOutRoutes) => void;
}

const CashOutRoutesStore = create<CashOutRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "CashOutRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getCashOutRoutesData() {
  return CashOutRoutesStore.getState().routes;
}

export function setCashOutRoutesData(data: CashOutRoutes) {
  CashOutRoutesStore.getState().setRoutes(data);
}
