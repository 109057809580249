import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Create } from "./types";

interface PixKeyCreateState {
  createKey: Create;
  setCreateKey: (data: Create) => void;
}

const PixKeyCreate = create<PixKeyCreateState>()(
  persist(
    (set) => ({
      createKey: {},
      setCreateKey: (data) => set(() => ({ createKey: data })),
    }),
    {
      name: "PixCreateStore", // Identificador no LocalStorage
    },
  ),
);

export function getCreateKey() {
  return PixKeyCreate.getState().createKey;
}

export function setCreateKey(data: Create) {
  PixKeyCreate.getState().setCreateKey(data);
}
