import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { Login } from "./types";

interface LoginState {
  login: Login;
  setLogin: (data: Login) => void;
}

const LoginStore = create<LoginState>()(
  persist(
    (set) => ({
      login: {},
      setLogin: (data) => set(() => ({ login: data })),
    }),
    {
      name: "LoginStore", // Identificador no LocalStorage
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export function getLogin() {
  return LoginStore.getState().login;
}

export function setLogin(data: Login) {
  LoginStore.getState().setLogin(data);
}
