import { IHeader } from "./types";

const HeaderEvent = (props: IHeader) => {
  const customEvent = new CustomEvent<IHeader>("headerChange", {
    detail: props,
  });
  window.dispatchEvent(customEvent);
};

export { HeaderEvent };
