export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export type HttpRequest = {
  method: HttpMethod;
  url: string;
  headers?: Record<string, string>;
  body?: any;
  params?: Record<string, string>;
};

export const HTTP_STATUS_CODES = {
  ok: 200,
  created: 201,
  accepted: 202,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  internalServerError: 500,
  notImplemented: 501,
};

export type HttpStatusCode =
  (typeof HTTP_STATUS_CODES)[keyof typeof HTTP_STATUS_CODES];

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode;
  headers?: Record<string, string>;
  body: T;
};

export interface Interceptor {
  onFulfilled?: (value: any) => any | Promise<any>;
  onRejected?: (error: any) => any;
}

export interface InterceptorManager {
  use(interceptor: Interceptor): number;
  eject(id: number): void;
  clear(): void;
}

interface Interceptors {
  request: InterceptorManager;
  response: InterceptorManager;
}

export interface HttpClient {
  request<T>(request: HttpRequest): Promise<HttpResponse<T>>;
  // interceptors: Interceptors;
}
