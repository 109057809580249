import { Toastr } from "@hyperlocal/vital";
import type { IconsValuesEnum } from "@hyperlocal/vital-icons";

type ToastPosition = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";

type NotificationType = "success" | "error" | "info";

type NotificationTypeMap = {
  icon: IconsValuesEnum;
  title: string;
};

export type Notification = {
  title?: string;
  message: string;
  type: NotificationType;
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  icon?: IconsValuesEnum;
  isCloseable?: boolean;
  timeout?: number;
};

export type NotificationMessage = Notification["message"];

/**
 * A função `toast` é usada para exibir uma notificação com um título, mensagem, tipo, posição, ícone
 * e tempo limite especificados.
 * @param {Notification} options - Opções para a notificação.
 * @param {string} [options.title] - O título da notificação. Se não for fornecido, um título padrão baseado no
 * tipo da notificação será usado.
 * @param {string} options.message - A mensagem da notificação.
 * @param {"success" | "error" | "info"} options.type - O tipo da notificação.
 * @param {"top-left" | "top-right" | "bottom-left" | "bottom-right"} [options.position="top-right"] - A posição da notificação.
 * @param {IconsValuesEnum} [options.icon] - O ícone da notificação.
 * @param {boolean} [options.isCloseable=true] - Define se a notificação é fechável.
 * @param {number} [options.timeout=5] - O tempo limite da notificação em segundos.
 * @returns {void}
 * @example toast({
 *    title: "Título da notificação",
 *    message: "Mensagem da notificação",
 *    type: "success",
 *    position: "topRight",
 *    icon: "TagsAlertsTickCircle",
 *    isCloseable: true,
 *    timeout: 5,
 * });
 */
export function toast({
  title,
  message,
  type,
  position = "top-right",
  icon,
  isCloseable = true,
  timeout = 5,
}: Notification): void {
  const types: Record<NotificationType, IconsValuesEnum> = {
    success: "TagsAlertsTickCircle",
    error: "TagsAlertsDanger",
    info: "TagsAlertsInfoCircle",
  };

  const positionMappings = {
    "top-left": "topLeft",
    "top-right": "topRight",
    "bottom-left": "bottomLeft",
    "bottom-right": "bottomRight",
  };

  const notificationTypeMappings: Record<
    NotificationType,
    NotificationTypeMap
  > = {
    success: {
      icon: types.success,
      title: "Sucesso",
    },
    error: {
      icon: types.error,
      title: "Erro",
    },
    info: {
      icon: types.info,
      title: "Informação",
    },
  };

  const notification = {
    color: type,
    message,
    title: title || notificationTypeMappings[type].title,
    position: positionMappings[position] as ToastPosition,
    isCloseable,
    icon: icon || (notificationTypeMappings[type].icon as IconsValuesEnum),
    timeout,
  };

  Toastr.open(notification);
}

/**
A função `error` adiciona uma notificação de erro com uma mensagem padrão caso nenhuma mensagem
seja fornecida.
@param {NotificationMessage} [message] - O parâmetro message é um parâmetro opcional do tipo NotificationMessage.
Ele representa a mensagem de erro que será exibida na notificação de erro. Se nenhuma message for fornecida,
uma mensagem de erro padrão de "Ocorreu um erro inesperado." será utilizada.
@example error("Ocorreu um erro inesperado.")
*/
function error(message?: NotificationMessage) {
  toast({
    type: "error",
    message: message || "Ocorreu um erro inesperado.",
  });
}

/**
A função `success` adiciona uma notificação de sucesso com uma mensagem opcional.
@param {NotificationMessage} [message] - O parâmetro message é um parâmetro opcional do tipo NotificationMessage.
É usado para especificar uma mensagem personalizada de sucesso para a notificação. Se nenhuma mensagem personalizada
for fornecida, a mensagem padrão de sucesso "Operação realizada com sucesso." será utilizada.
@example success("A operação foi realizada com sucesso.")
*/

function success(message?: NotificationMessage) {
  toast({
    type: "success",
    message: message || "Operação realizada com sucesso.",
  });
}

/**
A função `info` adiciona uma notificação de informação com uma mensagem fornecida.
@param {NotificationMessage} message - O parâmetro message é do tipo NotificationMessage. Ele
representa o conteúdo da mensagem da notificação que será exibida.
@example info("Informação importante.")
*/
function info(message: NotificationMessage) {
  toast({
    type: "info",
    message,
  });
}

toast.info = info;
toast.success = success;
toast.error = error;
