import { Profiles } from "../../services/types";

type Products = { guid_product: string; name: string; status: boolean }[];

function filterProducts(products: Products, guidProduct: string) {
  let status;
  products
    .filter((e) => e.guid_product === guidProduct)
    .forEach((e) => {
      status = e.status;
    });
  return status;
}

type Guids = Profiles & { guid_account: string };

export function validateProducts(guids: Guids, guidProduct: string) {
  let permission;
  guids.Customers.filter(
    (e: { guid_account: any }) => e.guid_account === guids.guid_account,
  ).forEach((e) => {
    permission = filterProducts(e.Products, guidProduct);
  });
  return permission;
}
