import { env } from "../config/env";

export const {
  MANAGER_API_URL,
  LOGIN_API_URL,
  TED_API_URL,
  PIX_API_URL,
  DIGITAL_API_URL,
  TOKEN_API_URL,
  AUTH_COGNITO_USER_POOL_CLIENT_ID,
  AUTH_COGNITO_USER_POOL_ID,
  STATEMENTS_API_URL,
  CONTACTS_API_URL,
  P2P_API_URL,
  AGENDA_API_URL,
  SPOT_API_URL,
  ACCOUNT_MANAGEMENT_API_URL,
  ACCESS_CONTROL_API_URL,
  FINANCE_PAYMENTS_API_URL,
  PENDENCY_LIST_API_URL,
  MANAGER_API_PASSWORD,
  MANAGER_API_USERNAME,
  IDENTITY_TOKEN,
  IDENTITY_POLICY_ID,
  IDENTITY_ENABLED,
  IDENTITY_MOBILE_TOKEN,
  IDENTITY_SDK_VERSION,
  INTERNAL_STATEMENTS_API_URL,
  INTERNAL_STATEMENTS_API_TOKEN,
} = env;
