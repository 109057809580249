import type { User } from "../../domain";
import type { UserStore } from "../store";

type UpdateUserStore = Pick<UserStore, "updateUser">;

export class UpdateUserUseCase {
  constructor(private userStore: UpdateUserStore) {}

  execute(user: User): void {
    this.userStore.updateUser(user);
  }
}
