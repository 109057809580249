import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Token } from "./types";

interface TokenState {
  token: Token;
  setToken: (data: Token) => void;
}

const TokenStore = create<TokenState>()(
  persist(
    (set) => ({
      token: {},
      setToken: (data) => set(() => ({ token: data })),
    }),
    {
      name: "TokenStore", // Identificador no LocalStorage
    },
  ),
);

export function getTokenData() {
  return TokenStore.getState().token;
}

export function setTokenData(data: Token) {
  TokenStore.getState().setToken(data);
}
