import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ChargeRoutes } from "./types";

interface ChargeRoutesState {
  routes: ChargeRoutes;
  setRoutes: (data: ChargeRoutes) => void;
}

const ChargeRoutesStore = create<ChargeRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "ChargeRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getChargeRoutesData() {
  return ChargeRoutesStore.getState().routes;
}

export function setChargeRoutesData(data: ChargeRoutes) {
  ChargeRoutesStore.getState().setRoutes(data);
}
