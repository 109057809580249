import { useEffect } from "react";
import { Token } from "../../store/Token/types";
import { eventIdentifierMap, EventName } from "../../utils/eventIdentifier";

type CustomEventProps = {
  eventName: EventName;
  callback: EventListenerOrEventListenerObject;
  options?: boolean | AddEventListenerOptions;
};

type DispatchEventProps<T> = {
  eventName: EventName;
  eventInitDict?: CustomEventInit<T>;
};

export type TokenRequest = {
  request: Token["request"];
  eventIdentifier: EventName;
  handleDate?: boolean;
};

interface TokenRequestInit extends CustomEventInit {
  detail: TokenRequest;
}

type DispatchTokenEvent = TokenRequestInit;

export const useListenCustomEvent = ({
  eventName,
  callback,
  options,
}: CustomEventProps) => {
  useEffect(() => {
    const eventIdentifier = eventIdentifierMap[eventName];

    window.addEventListener(eventIdentifier, callback, options);

    return () => {
      window.removeEventListener(eventIdentifier, callback, options);
    };
  }, [callback, eventName, options]);
};

export const useDispatchCustomEvent = () => {
  const dispatch = <T>({ eventName, eventInitDict }: DispatchEventProps<T>) => {
    const eventIdentifier = eventIdentifierMap[eventName];
    return dispatchEvent(new CustomEvent(eventIdentifier, eventInitDict));
  };

  return dispatch;
};

export const useDispatchTokenEvent = (config: DispatchTokenEvent) => {
  const dispatch = () => {
    return dispatchEvent(
      new CustomEvent(eventIdentifierMap.REQUEST_TOKEN, {
        ...config,
        detail: {
          ...config.detail,
          eventIdentifier: eventIdentifierMap[config.detail.eventIdentifier],
        },
      }),
    );
  };
  return dispatch;
};
