import { Amplify } from "aws-amplify";
import * as AmplifyAuth from "aws-amplify/auth";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { getVertical } from "../../../utils";
import { getCognito } from "../../../utils/local-storage/get-cognito";
import { LocalStorageAdapter } from "../../cache/local-storage-adapter";
import type {
  AuthenticationService,
  ConfirmResetPasswordParams,
  ConfirmSignInInputParams,
  FetchAuthSessionParams,
  FetchAuthSessionResponse,
  ResetPasswordParams,
  SignInParams,
  SignOutParams,
  UpdateMFAPreferenceParams,
  UpdatePasswordParams,
  VerifyTOTPSetupParams,
} from "./authentication-service";

type CognitoAuthConfig = {
  userPoolId: string;
  userPoolClientId: string;
};

const localStorageInstance = new LocalStorageAdapter();

function getErrorMessage(error: Error): string {
  if (!error) {
    return "Ocorreu um erro desconhecido.";
  }

  const errorName = error.name;
  const errorMessages: Record<string, string> = {
    LimitExceededException: "Limite de tentativas excedido",
    NotAuthorizedException: "Usuário ou senha incorretos",
    EmptyUpdatePassword: "A senha não pode ser vazia",
  };
  return errorMessages[errorName] || error.message;
}

export class AmplifyAuthenticationService implements AuthenticationService {
  constructor(private authConfig: CognitoAuthConfig) {
    if (getVertical() !== "hyperlocal") {
      const userPoolClientId = getCognito();

      if (userPoolClientId) this.authConfig.userPoolClientId = userPoolClientId;
    }

    this.configureAuth(this.authConfig);
    cognitoUserPoolsTokenProvider.setKeyValueStorage(localStorageInstance);

    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  public configureAuth(authConfig: CognitoAuthConfig): void {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: authConfig.userPoolId,
          userPoolClientId: authConfig.userPoolClientId,
        },
      },
    });
  }

  async signIn(props: SignInParams): Promise<AmplifyAuth.SignInOutput> {
    return AmplifyAuth.signIn({
      username: props.email.toLowerCase(),
      password: props.password,
      options: {
        authFlowType: "CUSTOM_WITH_SRP",
        clientMetadata: {
          challenge: "AccountInfo",
        },
      },
    });
  }

  async resetPassword(
    props: ResetPasswordParams,
  ): Promise<AmplifyAuth.ResetPasswordOutput> {
    return AmplifyAuth.resetPassword({ username: props.email.toLowerCase() });
  }

  async confirmResetPassword(props: ConfirmResetPasswordParams): Promise<void> {
    return AmplifyAuth.confirmResetPassword({
      username: props.email.toLowerCase(),
      newPassword: props.newPassword,
      confirmationCode: props.confirmationCode,
    });
  }

  async confirmSignIn(
    props: ConfirmSignInInputParams,
  ): Promise<AmplifyAuth.ConfirmSignInOutput> {
    return AmplifyAuth.confirmSignIn({
      challengeResponse: props.challenge,
      options: {
        clientMetadata: {
          vertical: "Banking",
          challenge: "AccountInfo",
        },
      },
    });
  }

  async signOut(props?: SignOutParams): Promise<void> {
    await AmplifyAuth.signOut(props);
  }

  async setUpTOTP(): Promise<AmplifyAuth.SetUpTOTPOutput> {
    return await AmplifyAuth.setUpTOTP();
  }

  async fetchAuthSession(
    props?: FetchAuthSessionParams,
  ): Promise<FetchAuthSessionResponse> {
    const session = await AmplifyAuth.fetchAuthSession(props);
    const isBanking =
      session.tokens?.accessToken.payload.is_banking?.toString();
    const isMfaEnabled =
      session.tokens?.accessToken.payload.is_mfa_active?.toString();
    const accessToken = session.tokens?.accessToken.toString();
    const userId = session.tokens?.accessToken.payload.user_id?.toString();
    const email = session.tokens?.idToken?.payload.email?.toString();
    return {
      isMfaEnabled,
      isBanking,
      accessToken,
      userId,
      email,
    };
  }

  async updateMFAPreference({
    totp,
  }: UpdateMFAPreferenceParams): Promise<void> {
    return AmplifyAuth.updateMFAPreference({ totp });
  }

  async verifyTOTPSetup({ code }: VerifyTOTPSetupParams): Promise<void> {
    return AmplifyAuth.verifyTOTPSetup({ code });
  }

  async updatePassword({
    oldPassword,
    newPassword,
  }: UpdatePasswordParams): Promise<void> {
    try {
      await AmplifyAuth.updatePassword({
        oldPassword,
        newPassword,
      });
    } catch (error: any) {
      console.log("error", error);
      const errorMessage = getErrorMessage(error);
      throw new Error(errorMessage);
    }
  }

  async getAccessToken(): Promise<string> {
    const keys = Object.keys(localStorage);
    const accessTokenKey = keys.find(
      (key) =>
        key.startsWith("CognitoIdentityServiceProvider") &&
        key.endsWith("accessToken"),
    );

    const accessToken = await localStorageInstance.getItem(
      accessTokenKey || "",
    );
    return accessToken ?? "";
  }

  async isAuthenticated() {
    let currentUser;

    try {
      currentUser = await AmplifyAuth.getCurrentUser();
    } catch (error) {
      console.error(error);
    }

    return Boolean(currentUser);
  }
}
