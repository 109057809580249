import { create } from "zustand";
import { persist } from "zustand/middleware";
import { BalanceRoutes } from "./types";

interface BalanceRoutesState {
  routes: BalanceRoutes;
  setRoutes: (data: BalanceRoutes) => void;
}

const BalanceRoutesStore = create<BalanceRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "BalanceRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getBalanceRoutesData() {
  return BalanceRoutesStore.getState().routes;
}

export function setBalanceRoutesData(data: BalanceRoutes) {
  BalanceRoutesStore.getState().setRoutes(data);
}
