import { create } from "zustand";
import { persist } from "zustand/middleware";
import { P2P } from "./types";

interface P2PState {
  P2P: P2P;
  setP2P: (data: P2P) => void;
}

const P2PStore = create<P2PState>()(
  persist(
    (set) => ({
      P2P: {},
      setP2P: (data) => set(() => ({ P2P: data })),
    }),
    {
      name: "P2PPaymentData", // Identificador no LocalStorage
    },
  ),
);

export function getP2PData() {
  return P2PStore.getState().P2P;
}

export function setP2PData(data: P2P) {
  P2PStore.getState().setP2P(data);
}
