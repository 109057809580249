import { balanceKeys } from "./balance";
import { chargeKeys } from "./charge";
import { contactKeys } from "./contacts";
import { receiptKeys } from "./receipt";
import { teamAccessKeys } from "./team-access";

export const queries = {
  receiptKeys,
  contactKeys,
  balanceKeys,
  chargeKeys,
  teamAccessKeys,
};
