import { HttpClient } from "../../../infra/http";
import { AccountIdHttpClientDecorator } from "../../../main/decorators/account-id-http-client-decorator";
import { makeAxiosHttpClient } from "../../../main/factories";
import { makeAmplifyAuthenticationService } from "../make-authentication-service";
import { makeAccountStoreFactory } from "../stores/make-account-store-factory";
import { makeUserStoreFactory } from "../stores/make-user-store-factory";

export const makeAccountIdHttpClientDecoratorFactory = (): HttpClient => {
  return new AccountIdHttpClientDecorator(
    makeAxiosHttpClient(),
    makeAmplifyAuthenticationService(),
    makeUserStoreFactory(),
    makeAccountStoreFactory(),
  );
};
