import {
  AuthenticationService,
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "../../infra";

export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authenticationService: AuthenticationService,
  ) {}

  async request<T>(request: HttpRequest): Promise<HttpResponse<T>> {
    const currentAccessToken =
      await this.authenticationService.getAccessToken();
    return this.httpClient.request<T>({
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${currentAccessToken}`,
      },
    });
  }
}
