import { failure, success } from "../../domain";
import { AccountStore } from "../../domain/store";
import {
  GetAccountInfoUseCase,
  GetAccountInfoUseCaseRequest,
  GetAccountInfoUseCaseResponse,
} from "../../domain/use-cases";
import {
  AccountMapper,
  GetAccountInfoResponseDTO,
  HTTP_STATUS_CODES,
  HttpClient,
} from "../../infra";

export class GetAccountInfoUseCaseImpl implements GetAccountInfoUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly accountStore: AccountStore,
  ) {}

  async execute(
    params: GetAccountInfoUseCaseRequest,
  ): Promise<GetAccountInfoUseCaseResponse> {
    const response = await this.httpClient.request<GetAccountInfoResponseDTO>({
      method: "GET",
      url: this.url,
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.statusCode) {
      case HTTP_STATUS_CODES.ok:
        // this.accountStore.updateAccount(response.body);
        return success(AccountMapper.toHttpResponse(response.body));
      case HTTP_STATUS_CODES.badRequest:
        return failure("Usuário não encontrado.");
      case HTTP_STATUS_CODES.unauthorized:
        return failure("Usuário não autorizado.");
      default:
        return failure("Erro ao obter informações do conta.");
    }
  }
}
