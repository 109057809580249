export function getCognito() {
  const key = Object.keys(localStorage).find((key) => {
    return (
      key.startsWith("CognitoIdentityServiceProvider") &&
      key.endsWith("LastAuthUser")
    );
  });

  if (!key) return null;

  const pieces = key.split(/\./g);

  if (!pieces || pieces.length !== 3) return null;

  return pieces[1];
}
