import { useEffect, useState } from "react";
import { getAuth } from "../../hyperlocal-banking-utility";

function useIsUserBlocked() {
  const [isUserBlocked, setUserBlocked] = useState({
    cadastralBlock: false,
    statusFetched: false,
  });

  const getUserInfo = async () => {
    const { profiles, hasCustomers } = await getAuth();

    setUserBlocked({
      cadastralBlock:
        hasCustomers && profiles.Customers[0]?.status_account === "B",
      statusFetched: true,
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return isUserBlocked;
}

export default useIsUserBlocked;
