import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { User } from "../../../domain";
import { UserStore } from "../../../domain/store/user-store";

export const useUserStore = create(
  persist<UserStore>(
    (set, get) => ({
      user: null,
      getUser: () => get().user,
      updateUser: (user: User) => set({ user }),
      deleteUser: () => set({ user: null }),
    }),
    {
      name: "user-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
