import {
  avecTheme,
  crossXTheme,
  customTheme,
  go2goTheme,
  hyperlocalTheme,
  timoTheme,
  wowTheme,
} from "@hyperlocal/vital";
import { getVertical } from "./local-storage";

const themes = {
  hyperlocal: hyperlocalTheme,
  avec: avecTheme,
  crossX: crossXTheme,
  timo: timoTheme,
  wow: wowTheme,
  go2go: go2goTheme,
  custom: customTheme,
};

const theme = getVertical();

const themeProvider = themes[theme];

export { themeProvider };
