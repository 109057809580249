import { ValidateIdentityResponse } from "../infra/services/identity/identity-service";
import { makeCafIdentityServiceFactory } from "../main/factories/services/make-caf-identity-service-factory";
import { useAccountStore } from "../presentation/hooks/account-store";

const identityService = makeCafIdentityServiceFactory();

type ValidateIdentityProps<S, E> = {
  onSuccess: (response: ValidateIdentityResponse) => Promise<S> | S;
  onFailure?: (errorMessage: string) => Promise<E> | E;
};

export async function validateIdentity<S, E>({
  onSuccess,
  onFailure,
}: ValidateIdentityProps<S, E>) {
  const account = useAccountStore.getState().account;
  const document = account!.document;
  const response = await identityService?.validateIdentity({ document });

  return response?.isAuthorized
    ? onSuccess(response)
    : onFailure?.(response?.message ?? "Não autorizado.");
}
