import { QueryClientProvider } from "@tanstack/react-query";
import { globalQueryClient } from "../../lib";

export function GlobalQueryClientProvider({ children }: any) {
  return (
    <QueryClientProvider client={globalQueryClient}>
      {children}
    </QueryClientProvider>
  );
}
