import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { AccountStore } from "../../../domain/store/account-store";
import { getVerticalAccountId } from "../vertical-store";

export const useAccountStore = create(
  persist<AccountStore>(
    (set) => ({
      account: null,
      fok: undefined,
      currentAccountId: getVerticalAccountId(),
      updateAccount: (account) => set({ account }),
      deleteAccount: () => set((state) => ({ ...state, account: null })),
      updateAccountId: (accountId: string) =>
        set((state) => ({ ...state, currentAccountId: accountId })),
      clear: () =>
        set({
          account: null,
          currentAccountId: undefined,
        }),
      updateFok: (fok: number) => set((state) => ({ ...state, fok })),
    }),
    {
      name: "account-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
