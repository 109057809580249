import { HttpClient } from "../../../infra/http";
import { AuthorizeHttpClientDecorator } from "../../../main/decorators";
import { makeAxiosHttpClient } from "../../../main/factories";
import { makeAmplifyAuthenticationService } from "../make-authentication-service";

export const makeAuthorizeHttpClientDecoratorFactory = (): HttpClient => {
  return new AuthorizeHttpClientDecorator(
    makeAxiosHttpClient(),
    makeAmplifyAuthenticationService(),
  );
};
