import { IDENTITY_ENABLED, IDENTITY_POLICY_ID } from "../../../config";
import type { IdentityService } from "../../../infra/services/identity";
import { cafIdentityService } from "../../../infra/services/identity";
import { IdentitySDK } from "../../../infra/services/identity/identity-sdk";
import { initializeIdentitySdk } from "../../../lib";

let identityService: IdentityService | null = null;
let identitySDK: IdentitySDK | null = null;

export function makeCafIdentityServiceFactory(): IdentityService | null {
  if (identityService) {
    return identityService;
  }

  if (IDENTITY_ENABLED !== "true") {
    identityService = {
      validateIdentity() {
        return Promise.resolve({
          isAuthorized: true,
          message: "Identity is disabled",
        });
      },
    };
    return identityService;
  }

  try {
    identitySDK = initializeIdentitySdk();
    identityService = cafIdentityService(IDENTITY_POLICY_ID, identitySDK);
    return identityService;
  } catch (error) {
    console.error("Error initializing Identity SDK:", error);
    throw error;
  }
}
