import { create } from "zustand";
import { persist } from "zustand/middleware";
import { User } from "./types";

interface UserState {
  user: User;
  setUser: (data: User) => void;
}

const UserStore = create<UserState>()(
  persist(
    (set) => ({
      user: {},
      setUser: (data) => set(() => ({ user: data })),
    }),
    {
      name: "UserStore", // Identificador no LocalStorage
    },
  ),
);

export function getUser() {
  return UserStore.getState().user;
}

export function setUser(data: User) {
  UserStore.getState().setUser(data);
}
