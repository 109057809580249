export function formatCurrency(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  if (!value && typeof value !== "number") return "R$ 0,00";

  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    ...options,
  })
    .format(value)
    .replace(/\u00A0/g, " ");
}
