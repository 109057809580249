import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Transfer } from "./types";

interface TransferState {
  transfer: Transfer;
  setTransfer: (data: Transfer) => void;
}

const TransferStore = create<TransferState>()(
  persist(
    (set) => ({
      transfer: {},
      setTransfer: (data) => set(() => ({ transfer: data })),
    }),
    {
      name: "TransferStore", // Identificador no LocalStorage
    },
  ),
);

export function getTransferData() {
  return TransferStore.getState().transfer;
}

export function setTransferData(data: Transfer) {
  TransferStore.getState().setTransfer(data);
}
