import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Ted } from "./types";

interface TedState {
  ted: Ted;
  setTed: (data: Ted) => void;
}

const TedStore = create<TedState>()(
  persist(
    (set) => ({
      ted: {},
      setTed: (data) => set(() => ({ ted: data })),
    }),
    {
      name: "TedPaymentData", // Identificador no LocalStorage
    },
  ),
);

export function getTedData() {
  return TedStore.getState().ted;
}

export function setTedData(data: Ted) {
  TedStore.getState().setTed(data);
}
