import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TokenRoutes } from "./types";

interface TokenRoutesState {
  routes: TokenRoutes;
  setRoutes: (data: TokenRoutes) => void;
}

const TokenRoutesStore = create<TokenRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "TokenRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getTokenRoutesData() {
  return TokenRoutesStore.getState().routes;
}

export function setTokenRoutesData(data: TokenRoutes) {
  TokenRoutesStore.getState().setRoutes(data);
}
