import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Charge } from "./types";

interface PixChargeState {
  pixCharge: Charge;
  setPixCharge: (data: Charge) => void;
}

const PixChargeStore = create<PixChargeState>()(
  persist(
    (set) => ({
      pixCharge: {},
      setPixCharge: (data) => set(() => ({ pixCharge: data })),
    }),
    {
      name: "PixChargeStore", // Identificador no LocalStorage
    },
  ),
);

export function getPixChargeData() {
  return PixChargeStore.getState().pixCharge;
}

export function setPixChargeData(data: Charge) {
  PixChargeStore.getState().setPixCharge(data);
}
