import {
  AUTH_COGNITO_USER_POOL_CLIENT_ID,
  AUTH_COGNITO_USER_POOL_ID,
} from "../../config";
import { AmplifyAuthenticationService } from "../../infra";

let singletonInstance: AmplifyAuthenticationService | null = null;

export function makeAmplifyAuthenticationService() {
  if (!singletonInstance) {
    singletonInstance = new AmplifyAuthenticationService({
      userPoolClientId: AUTH_COGNITO_USER_POOL_CLIENT_ID,
      userPoolId: AUTH_COGNITO_USER_POOL_ID,
    });
  }

  return singletonInstance;
}
