import type { User } from "../../domain";
import type { UserStore } from "../store";

type GetUserStore = Pick<UserStore, "getUser">;

export class GetUserUseCase {
  constructor(private userStore: GetUserStore) {}

  execute(): User | null {
    return this.userStore.getUser();
  }
}
