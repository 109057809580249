import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Delete } from "./types";

interface PixKeyDeleteState {
  delete: Delete;
  setDelete: (data: Delete) => void;
}

const PixDeleteKey = create<PixKeyDeleteState>()(
  persist(
    (set) => ({
      delete: {},
      setDelete: (data) => set(() => ({ delete: data })),
    }),
    {
      name: "PixDeleteKey", // Identificador no LocalStorage
    },
  ),
);

export function getDeletKey() {
  return PixDeleteKey.getState().delete;
}

export function setDeleteKey(data: Delete) {
  PixDeleteKey.getState().setDelete(data);
}
