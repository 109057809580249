import { useEffect, useState } from "react";

type SetStateFunction<Shape> = (newState: Partial<Shape>) => void;
type GetStateFunction<Shape> = () => Shape;

export function createStore<Shape>(
  initializer: (
    set: SetStateFunction<Shape>,
    get: GetStateFunction<Shape>,
  ) => Shape,
) {
  let currentState: Shape;
  const listeners = new Set<(state: Shape) => void>();

  const subscribe = (listener: (state: Shape) => void) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const set: SetStateFunction<Shape> = (newState) => {
    currentState = { ...currentState, ...newState };
    listeners.forEach((listener) => listener(currentState));
  };

  const get: GetStateFunction<Shape> = () => currentState;

  currentState = initializer(set, get);

  return {
    getState: get,
    setState: set,
    subscribe,
    useStore: <SelectorOutput>(
      selector: (state: Shape) => SelectorOutput,
    ): SelectorOutput => {
      const [state, setState] = useState<SelectorOutput>(() =>
        selector(currentState),
      );

      useEffect(() => {
        const unsubscribe = subscribe((updatedState) =>
          setState(() => selector(updatedState)),
        );
        return () => {
          unsubscribe();
        };
      }, [selector]);

      return state;
    },
  };
}
