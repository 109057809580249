import { ACCOUNT_MANAGEMENT_API_URL } from "../../../config";
import { GetAccountInfoUseCase } from "../../../domain";
import { GetAccountInfoUseCaseImpl } from "../../../infra/use-cases";
import { makeAccountIdHttpClientDecoratorFactory } from "../../../main/factories/decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../../main/factories/http/api-url-factory";
import { useAccountStore } from "../../../presentation/hooks/account-store";

const makeGetAccountUseCaseFactory = (): GetAccountInfoUseCase => {
  const accountStore = useAccountStore.getState();

  const apiUrl = makeApiUrlFactory({
    path: "api/Account/AccountInfo",
    baseUrl: ACCOUNT_MANAGEMENT_API_URL,
  });

  const httpClient = makeAccountIdHttpClientDecoratorFactory();
  return new GetAccountInfoUseCaseImpl(httpClient, apiUrl, accountStore);
};

export const getAccountUseCase = makeGetAccountUseCaseFactory();
