import { ACCOUNT_MANAGEMENT_API_URL } from "../../../config";
import { ListAccountsUseCase } from "../../../domain/use-cases";
import { ListAccountsUsecaseImpl } from "../../../infra";
import { makeUserStoreFactory } from "../../factories/stores";
import { makeAuthorizeHttpClientDecoratorFactory } from "../decorators";
import { makeApiUrlFactory } from "../http";

const makeListAccountsUsecaseFactory = (): ListAccountsUseCase => {
  const userStore = makeUserStoreFactory();
  const apiUrl = makeApiUrlFactory({
    path: "api/Account/AccountList",
    baseUrl: ACCOUNT_MANAGEMENT_API_URL,
  });
  const httpClient = makeAuthorizeHttpClientDecoratorFactory();
  return new ListAccountsUsecaseImpl(httpClient, apiUrl, userStore);
};

export const listAccountsUseCase = makeListAccountsUsecaseFactory();
