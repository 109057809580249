import { AccessGroupName } from "../domain";
import { useAccountStore, useUserStore as userStore } from "../presentation";

export const hasPermission = (allowedRoles: AccessGroupName[]) => {
  const user = userStore.getState().getUser();

  const accountStore = useAccountStore.getState();

  const selectedAccount = user?.accounts.find(
    (account) => account.accountId === accountStore.currentAccountId,
  );

  if (!selectedAccount) return false;

  return allowedRoles.includes(selectedAccount.accessGroup.accessGroupName);
};
