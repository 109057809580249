import DOMPurify from "dompurify";

/**
 * Função utilitária para sanitizar uma string usando DOMPurify.
 *
 * @param input - A string a ser sanitizada.
 * @returns A string sanitizada, segura para ser usada em HTML.
 * @example sanitizeValue("<script>alert('Seu site foi comprometido!');</script>"); // ""
 * @example sanitizeValue(" <a href="javascript:alert("Hello")">Hello!</a>"); // "<a>Hello!</a>"
 */
export function sanitizeValue(input: string): string {
  return DOMPurify.sanitize(input);
}
