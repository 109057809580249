import axios, { AxiosResponse } from "axios";
import { parseResponseError } from "../../services";
import { HttpClient, HttpRequest, HttpResponse } from "./http-client";

const APIInstance = axios.create();

APIInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  parseResponseError,
);

export class AxiosHttpClient implements HttpClient {
  async request<T>(request: HttpRequest): Promise<HttpResponse<T>> {
    let response;

    try {
      response = await APIInstance({
        ...request,
      });
    } catch (error: any) {
      response = error.response;
    }

    return {
      statusCode: response.status,
      body: response.data,
    };
  }
}
