import { create } from "zustand";
import { persist } from "zustand/middleware";
import { PixRoutes } from "./types";

interface PixRoutesState {
  routes: PixRoutes;
  setRoutes: (data: PixRoutes) => void;
}

const pixRoutesStore = create<PixRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "PixRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getPixRoutesData() {
  return pixRoutesStore.getState().routes;
}

export function setPixRoutesData(data: PixRoutes) {
  pixRoutesStore.getState().setRoutes(data);
}
