import { create } from "zustand";
import { persist } from "zustand/middleware";
import { DashboardRoutes } from "./types";

interface DashboardRoutesState {
  routes: DashboardRoutes;
  setRoutes: (data: DashboardRoutes) => void;
}

const DashboardRoutesStore = create<DashboardRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) =>
        set((prev) => ({
          ...prev,
          routes: {
            ...prev.routes,
            ...data,
          },
        })),
    }),
    {
      name: "DashboardRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getDashboardRoutesData() {
  return DashboardRoutesStore.getState().routes;
}

export function setDashboardRoutesData(data: DashboardRoutes) {
  DashboardRoutesStore.getState().setRoutes(data);
}
