import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { GetAccountInfoUseCaseRequest } from "../../../domain/use-cases";
import {
  globalQueryClient,
  QueryConfig,
  SuspenseQueryConfig,
} from "../../../lib/react-query";
import { getAccountUseCase } from "../../../main/factories/use-cases";
import { accountsQueryKeyFactory } from "./accounts-query-key-factory";

export type UseAccountInfo = {
  config?: QueryConfig<typeof getAccountUseCase.execute>;
} & GetAccountInfoUseCaseRequest;

export type UseSuspenseAccountInfo = {
  config?: SuspenseQueryConfig<typeof getAccountUseCase.execute>;
} & GetAccountInfoUseCaseRequest;

const fiveMinutes = 5 * 60 * 1000;

export function accountInfoQueryConfig({
  accountId,
  config = {},
}: UseAccountInfo) {
  return {
    queryKey: accountsQueryKeyFactory.detail(accountId),
    queryFn: () => getAccountUseCase.execute({ accountId }),
    fiveMinutes,
    ...config,
  };
}

export function accountInfoSuspenseQueryConfig({
  accountId,
  config = {},
}: UseSuspenseAccountInfo) {
  return {
    queryKey: accountsQueryKeyFactory.detail(accountId),
    queryFn: () => getAccountUseCase.execute({ accountId }),
    ...config,
  };
}

export function prefetchAccountInfo({ accountId }: UseAccountInfo) {
  return globalQueryClient.prefetchQuery(accountInfoQueryConfig({ accountId }));
}

export function useAccountInfo({ accountId, config = {} }: UseAccountInfo) {
  return useQuery(accountInfoQueryConfig({ accountId, config }));
}

export const useSuspenseAccountInfo = ({
  accountId,
  config = {},
}: UseAccountInfo) => {
  return useSuspenseQuery(
    accountInfoSuspenseQueryConfig({ accountId, config }),
  );
};
