import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TransfersRoutes } from "./types";

interface TransfersRoutesState {
  routes: TransfersRoutes;
  setRoutes: (data: TransfersRoutes) => void;
}

const TransfersRoutesStore = create<TransfersRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "TransfersRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getTransfersRoutesData() {
  return TransfersRoutesStore.getState().routes;
}

export function setTransfersRoutesData(data: TransfersRoutes) {
  TransfersRoutesStore.getState().setRoutes(data);
}
