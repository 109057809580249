import { IDebugging, CaptureContext } from "./types";

import { SentryDebugging } from "./sentry";

export class DebuggingController implements IDebugging {
  private debuggingService!: IDebugging;

  constructor(debuggingService: IDebugging) {
    this.debuggingService = debuggingService;
  }

  public init() {
    this.debuggingService.init();
  }

  public close() {
    this.debuggingService.close();
  }

  public captureException(error: any, context: CaptureContext) {
    this.debuggingService.captureException(error, context);
  }
}

export const DebuggingTrack = new DebuggingController(new SentryDebugging());
