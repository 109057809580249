import { useState } from "react";
import { Button } from "@hyperlocal/vital";
import { signOut } from "../../../hyperlocal-banking-utility";
import { getDashboardRoutesData } from "../../../store";
import { getVertical } from "../../../utils";
import { useAccountStore } from "../../hooks/account-store";
import { getMessage } from "./messages";
import { EmptyStateProps } from "./type";

const routes = getDashboardRoutesData();

export function EmptyState({ type }: Readonly<EmptyStateProps>) {
  const clearAccountStore = useAccountStore((store) => store.clear);
  const [loading, setLoading] = useState(false);
  const vertical = getVertical();
  const message = getMessage(vertical, type);

  const images = {
    hyperlocal: require("./images/hyperlocal.png"),
    avec: require("./images/avec.png"),
  };

  const handleSignout = async () => {
    try {
      setLoading(true);
      await signOut({ global: true });
    } catch (error) {
    } finally {
      setLoading(false);
    }
    clearAccountStore();
    history.pushState({}, "", routes.sessionLogin || "/session/login");
  };

  return (
    <div
      className="mobile:aspect-auto mobile:w-full mobile:h-full bg-neutral-white flex aspect-square w-1/3 flex-col items-center justify-center rounded-md"
      style={{ maxWidth: 500 }}
    >
      <div className="flex flex-1 flex-col items-center justify-center">
        <div>
          <img src={images.hyperlocal} alt={message} />
        </div>
        <span className="mobile:w-1/2 mobile:text-x2s w-1/3 text-center">
          {message}
        </span>
      </div>

      {vertical === "hyperlocal" && type === "NOT_FOUND" ? (
        <div className="mb-8">
          <Button variant="link" onClick={handleSignout} isLoading={loading}>
            Ir para o login
          </Button>
        </div>
      ) : null}
    </div>
  );
}
