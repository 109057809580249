import { StorageInterface } from "../../infra/cache/storage-interface";

type Listener<T> = (state: T) => void;

type PersistentStoreProps<T> = {
  initialState: T;
  storageKey: string;
  storage: StorageInterface;
};

export class CustomStorageImpl<T> {
  private state!: T;
  private listeners: Listener<T>[] = [];
  private storage: StorageInterface;
  private readonly storageKey: string;

  constructor({ storageKey, storage, initialState }: PersistentStoreProps<T>) {
    this.storageKey = storageKey;
    this.storage = storage;

    this.loadState().then((savedState) => {
      this.state = savedState !== null ? savedState : initialState;
      this.notify();
    });
  }

  async getState(): Promise<T> {
    return this.state;
  }

  async setState(newState: T): Promise<void> {
    this.state = newState;
    await this.saveState(newState);
    this.notify();
  }

  subscribe(listener: Listener<T>): () => void {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  private notify(): void {
    for (const listener of this.listeners) {
      listener(this.state);
    }
  }

  private async loadState(): Promise<T | null> {
    const serializedState = await this.storage.getItem(this.storageKey);
    return serializedState ? JSON.parse(serializedState) : null;
  }

  private async saveState(state: T): Promise<void> {
    const serializedState = JSON.stringify(state);
    await this.storage.setItem(this.storageKey, serializedState);
  }
}
