import { create } from "zustand";
import { persist } from "zustand/middleware";
import { GetKeys } from "./types";

interface IPixKeyStore {
  keys: GetKeys[];
  setPixKey: (data: GetKeys[]) => void;
}

const PixKeyStore = create<IPixKeyStore>()(
  persist(
    (set) => ({
      keys: [],
      setPixKey: (data) => set(() => ({ keys: data })),
    }),
    {
      name: "PixKeyStore", // Identificador no LocalStorage
    },
  ),
);

export function getPixKey() {
  return PixKeyStore.getState().keys;
}

export function setPixKey(data: GetKeys[]) {
  PixKeyStore.getState().setPixKey(data);
}
