import { StorageInterface } from "./storage-interface";

export class LocalStorageAdapter implements StorageInterface {
  async getItem(name: string): Promise<string | null> {
    return localStorage.getItem(name);
  }

  async setItem(name: string, value: string): Promise<void> {
    localStorage.setItem(name, value);
  }

  async removeItem(name: string): Promise<void> {
    localStorage.removeItem(name);
  }

  async clear(): Promise<void> {
    localStorage.clear();
  }
}
