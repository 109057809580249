import { useEffect, useState } from "react";
import { CurrentAccountStore } from "../../../domain/store/vertical-store";
import { storage } from "../../../main/factories/cache/make-persistent-store-factory";

const ACCOUNT_STORE_KEY = "AccountStore";

export function getVerticalAccountId(): string {
  const storedData = localStorage.getItem(ACCOUNT_STORE_KEY);
  const parsedData = JSON.parse(storedData || "{}");
  return parsedData?.accountId || "";
}

export function clearVerticalStore() {
  return localStorage.removeItem(ACCOUNT_STORE_KEY);
}

export function useVerticalStore(): CurrentAccountStore {
  const [verticalAccountId, setVerticalAccountId] =
    useState<string>(getVerticalAccountId);
  const [verticalAccountName, setVerticalAccountName] = useState<string>("");

  useEffect(() => {
    if (!verticalAccountId) {
      setVerticalAccountId(getVerticalAccountId());
    }
  }, [verticalAccountId]);

  useEffect(() => {
    const unsubscribe = storage.subscribe((state) => {
      setVerticalAccountId(state?.accountId || "");
    });
    return () => unsubscribe();
  }, []);

  async function updateVerticalAccountId(accountId: string) {
    const state = await storage.getState();
    setVerticalAccountId(accountId);
    await storage.setState({ name: state?.name || "", accountId });
  }

  async function updateVerticalAccountName(name: string) {
    const state = await storage.getState();
    setVerticalAccountName(name);
    await storage.setState({ name, accountId: state?.accountId || "" });
  }

  async function clear() {
    await storage.setState({ name: "", accountId: "" });
  }

  return {
    updateVerticalAccountId,
    updateVerticalAccountName,
    verticalAccountId,
    verticalAccountName,
    clear,
  };
}
