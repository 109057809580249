import { Dialog } from "@hyperlocal/vital2";
import { useAccountStore } from "../../hooks";

export type IdentityConfirmationDialogProps = {
  isOpen: boolean;
  onClose: (value: boolean) => void;
};

export function IdentityConfirmationDialog({
  isOpen,
  onClose,
}: Readonly<IdentityConfirmationDialogProps>) {
  const { account } = useAccountStore();
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Confirme sua identidade</Dialog.Title>
        </Dialog.Header>
        <span className="text-xs text-gray-700">
          O CPF {account?.document || "xxx.xxx.xxx-xx"} está vinculado ao seu
          usuário e precisamos confirmar sua identidade. Acesse o link de
          verificação para tirar uma selfie e enviar o documento. Aguarde 10
          minutos antes de tentar a transação novamente.
        </span>
        <a
          href="https://cadastro.io/509e7631fab992b039897816c7a91ed8"
          target="_blank"
          rel="noreferrer"
          className="text-primary-main underline"
        >
          https://cadastro.io/509e7631fab992b039897816c7a91ed8
        </a>{" "}
      </Dialog.Content>
    </Dialog.Root>
  );
}
