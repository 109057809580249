import { Fragment, PropsWithChildren } from "react";
import { AccessGroupName } from "../domain";
import { useAccountStore, useUserStore } from "../presentation";

type ProtectedComponentProps = {
  allowedRoles: Array<AccessGroupName>;
} & PropsWithChildren;

export const ProtectedComponent = ({
  allowedRoles,
  children,
}: ProtectedComponentProps) => {
  const { getUser } = useUserStore();
  const user = getUser();

  const accountStore = useAccountStore();

  const selectedAccount = user?.accounts.find(
    (account) => account.accountId === accountStore.currentAccountId,
  );

  if (!selectedAccount) return null;

  const hasPermission = allowedRoles.includes(
    selectedAccount.accessGroup.accessGroupName,
  );

  if (!hasPermission) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};
