import { Profiles } from "../../services/types";
import { validateProducts } from "../Permissions";
import { ICAF } from "./types";

type CafProps = Profiles & { guid_account: string };

export const Caf = async (data: any) => {
  const cafProduct = validateProducts(
    data,
    "35C565CC-4FB7-11ED-BDC3-0242AC120002",
  );

  if (!cafProduct) return { isSucess: true, attestation: "Não disponível" };

  const cpf = getOwnerCpf(data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const identity: ICAF = window.identity;

  const policyId = "plataforma_wow";

  try {
    const response = await identity.verifyPolicy(cpf, policyId);

    if (identity.isSdkError(response)) {
      // Erro ao executar o SDK
      return { isSucess: false, message: "Erro SDK CAF" };
    } else {
      const { isAuthorized, attestation } = response;

      if (isAuthorized) {
        // Usuário está autorizado
        // Enviar a attestation para seu backend e validá-la lá
        return { isSucess: true, attestation };
      } else {
        // Usuário não está autorizadz
        console.log("nao autorizado");
        return { isSucess: false, message: "Usuário não autorizado CAF" };
      }
    }
  } catch (e: any) {
    const mensagemRetorno = handleErrorMessages(e);
    return { isSucess: false, message: mensagemRetorno };
  }
};

const getOwnerCpf = (data: CafProps) => {
  const { Customers, guid_account: guidAccount } = data;
  let cpf = "";
  Customers.filter((e) => e.guid_account === guidAccount).forEach((e) => {
    cpf = e.cpf as string;
  });
  return cpf;
};

const errorMessagesMap: Record<string, string> = {
  "The user canceled the operation": "O usuário cancelou a operação.",
  "Invalid personId": "O Documento fornecido para validaçao nao e valido",
  "The personId provided in the body or path is not valid":
    "O documento fornecido para validação não é válido.",
  "User did not provide permission to collect location":
    "O usuário não forneceu permissão para coletar a localização.",
  "Operation to collect user location timeouted":
    "A operação para coletar a localização do usuário expirou.",
  "There was an error while trying to retrieve user location.":
    "Ocorreu um erro ao tentar recuperar a localização do usuário.",
  "Authentication method face_authentication is not supported by this version of the SDK":
    "O método de autenticação por face não é compatível com esta versão do SDK.",
  Unauthorized: "Sem autorização",
  "The authentication attempt is not pending":
    "A tentativa de autenticação não está pendente",
  "The identity does not have a phone number registered":
    "A identidade não tem um número de telefone cadastrado",
  "The identity does not have an email registered":
    "A identidade não tem um email cadastrado",
  "You must wait 30s before resending the code":
    "Você deve esperar 30s antes de reenviar o código",
  "The SDK is already running": "O SDK já está em execução",
  "Failed to make request": "Falha ao fazer a solicitação",
};

function handleErrorMessages(error: Error) {
  return errorMessagesMap[error.message] || error.message;
}
