import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ReceiptRoutes } from "./types";

interface ReceiptRoutesState {
  routes: ReceiptRoutes;
  setRoutes: (data: ReceiptRoutes) => void;
}

const ReceiptRoutesStore = create<ReceiptRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "ReceiptRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getReceiptRoutesData() {
  return ReceiptRoutesStore.getState().routes;
}

export function setReceiptRoutesData(data: ReceiptRoutes) {
  ReceiptRoutesStore.getState().setRoutes(data);
}
