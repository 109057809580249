import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ReceiptPayment } from "./types";

interface ReceiptPaymentState {
  receiptPayment: ReceiptPayment;
  setReceiptPayment: (data: ReceiptPayment) => void;
}

const ReceiptPaymentStore = create<ReceiptPaymentState>()(
  persist(
    (set) => ({
      receiptPayment: {},
      setReceiptPayment: (data) => set(() => ({ receiptPayment: data })),
    }),
    {
      name: "ReceiptPaymentData", // Identificador no LocalStorage
    },
  ),
);

export function getReceiptPaymentData() {
  return ReceiptPaymentStore.getState().receiptPayment;
}

export function setReceiptPaymentData(data: ReceiptPayment) {
  ReceiptPaymentStore.getState().setReceiptPayment(data);
}
