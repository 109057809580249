import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Boleto } from "./types";

interface BoletoState {
  boleto: Boleto;
  setBoleto: (data: Boleto) => void;
}

const BoletoStore = create<BoletoState>()(
  persist(
    (set) => ({
      boleto: {},
      setBoleto: (data) => set(() => ({ boleto: data })),
    }),
    {
      name: "BoletoPaymentData", // Identificador no LocalStorage
    },
  ),
);

export function getBoletoData() {
  return BoletoStore.getState().boleto;
}

export function setBoletoData(data: Boleto) {
  BoletoStore.getState().setBoleto(data);
}
