import { Vertical } from "../../../utils";
import { MessageType } from "./type";

const messages: Partial<Record<Vertical, Record<MessageType, string>>> = {
  hyperlocal: {
    NOT_FOUND:
      "Houve um problema e sua sessão foi encerrada. Por favor, faça o login novamente para continuar.",
    UNAUTHORIZED:
      "Você não possui acesso a essa conta. \nFale com o proprietário.",
    AWAIT_SETUP:
      "Estamos preparando sua conta, ela estará disponível em breve.",
  },
  avec: {
    NOT_FOUND:
      "Houve um problema e sua sessão foi encerrada. Por favor, faça o login novamente para continuar.",
    UNAUTHORIZED: "Você não possui acesso a essa conta. \nFale com o master.",
    AWAIT_SETUP:
      "Estamos preparando sua conta, ela estará disponível em breve.",
  },
  crossX: {
    NOT_FOUND:
      "Houve um problema e sua sessão foi encerrada. Por favor, faça o login novamente para continuar.",
    UNAUTHORIZED: "Você não possui acesso a essa conta. \nFale com o master.",
    AWAIT_SETUP:
      "Estamos preparando sua conta, ela estará disponível em breve.",
  },
};

export const getMessage = (vertical: Vertical, type: MessageType) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return messages[vertical || "hyperlocal"]![type];
};
