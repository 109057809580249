import { SignOutUseCase } from "../../../domain";
import { SignOutUseCaseImpl } from "../../../infra/use-cases";
import { makeAmplifyAuthenticationService, storage } from "../../factories";
import {
  makeAccountStoreFactory,
  makeUserStoreFactory,
} from "../../factories/stores";

export const makeSignOutUsecaseFactory = (): SignOutUseCase => {
  const accountStore = makeAccountStoreFactory();
  const usetore = makeUserStoreFactory();
  const authenticationService = makeAmplifyAuthenticationService();
  return new SignOutUseCaseImpl(
    authenticationService,
    accountStore,
    usetore,
    storage,
    window.history,
  );
};

export const signOutUseCase = makeSignOutUsecaseFactory();
