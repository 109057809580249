import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Pix } from "./types";

interface PixState {
  pix: Pix;
  setPix: (data: Pix) => void;
}

const PixStore = create<PixState>()(
  persist(
    (set) => ({
      pix: {},
      setPix: (data) => set(() => ({ pix: data })),
    }),
    {
      name: "PixPaymentData", // Identificador no LocalStorage
    },
  ),
);

export function getPixData() {
  return PixStore.getState().pix;
}

export function setPixData(data: Pix) {
  PixStore.getState().setPix(data);
}
