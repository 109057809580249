import { create } from "zustand";
import { persist } from "zustand/middleware";
import { StatementsRoutes } from "./types";

interface StatementsRoutesState {
  routes: StatementsRoutes;
  setRoutes: (data: StatementsRoutes) => void;
}

const statementsRoutesStore = create<StatementsRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "StatementsRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getStatementsRoutesData() {
  return statementsRoutesStore.getState().routes;
}

export function setStatementsRoutesData(data: StatementsRoutes) {
  statementsRoutesStore.getState().setRoutes(data);
}
