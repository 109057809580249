import { AccountStore, UserStore } from "../../domain/store";
import {
  AuthenticationService,
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "../../infra";

const getLocalStorageAccountId = () => {
  try {
    const accountStore = window.localStorage.getItem("account-store");

    const parsed = JSON.parse(accountStore || "{}");

    return parsed?.state?.currentAccountId || "";
  } catch (error) {
    console.debug("getLocalStorageAccountId account-id-http-client-decorator");
  }
};

export class AccountIdHttpClientDecorator implements HttpClient {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authenticationService: AuthenticationService,
    private readonly userStore: Pick<UserStore, "getUser">,
    private readonly accountStore: Pick<AccountStore, "currentAccountId">,
  ) {}

  async request<T>(request: HttpRequest): Promise<HttpResponse<T>> {
    const currentAccessToken =
      await this.authenticationService.getAccessToken();
    const defaultAccountId =
      getLocalStorageAccountId() ||
      this.userStore.getUser()?.accounts[0].accountId;

    return this.httpClient.request<T>({
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${currentAccessToken}`,
        "account-id": defaultAccountId || "",
      },
    });
  }
}
