import { create } from "zustand";
import { persist } from "zustand/middleware";
import { SalesRoutes } from "./types";

interface SalesRoutesState {
  routes: SalesRoutes;
  setRoutes: (data: SalesRoutes) => void;
}

const salesRoutesStore = create<SalesRoutesState>()(
  persist(
    (set) => ({
      routes: {},
      setRoutes: (data) => set(() => ({ routes: data })),
    }),
    {
      name: "SalesRoutesData", // Identificador no LocalStorage
    },
  ),
);

export function getSalesRoutesData() {
  return salesRoutesStore.getState().routes;
}

export function setSalesRoutesData(data: SalesRoutes) {
  salesRoutesStore.getState().setRoutes(data);
}
