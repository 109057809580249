import axios from "axios";
import { API } from "../../services";
import { BalanceResponse } from "./types";

export const requestBalance = async () => {
  try {
    const { data } = await API.statements.get<BalanceResponse>("/Balance");

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
};
