export type Vertical =
  | "hyperlocal"
  | "avec"
  | "crossX"
  | "timo"
  | "wow"
  | "go2go";

export const getVertical = () => {
  const vertical = localStorage.getItem("vertical") || "hyperlocal";

  return vertical as Vertical;
};
